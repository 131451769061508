import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic04 from '../assets/images/pic04.jpg'

const Compensation = props => (
  <Layout>
    <Helmet>
      <title>Compensation - Horovatin Consulting Ltd.</title>
      <meta name="description" content="Compensation Services" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Compensation</h1>
          </header>
          <span className="image main">
            <img
              src={pic04}
              alt="View from the Leighton Art Centre, with the Rocky Mountains in the distance and grassy hills with trees. An empty frame is suspended in the middle, showing the view beyond as if in a painting."
            />
          </span>
          <p>
            Compensation is more than a paycheque. An approach using a Total
            Rewards philosophy is increasingly important these days in order to
            build and maintain a loyal workforce. Companies spend large sums of
            money on programs for employees for their wellness and engagement,
            but times change and the wants and needs of employees also change.
          </p>
          <p>
            Let us review your compensation plans to help you develop an
            approach that will motivate your employees to do their best through
            tailored Total Rewards offerings.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Compensation
